import _ from 'lodash'
import {
  CHANGE_GRID_STATUS, CHANGE_GRID_STATUS_DEV, CLEAN_GRID_CLICKS, CLEAN_FILTER_IN_GRID, REMOVE_GRID, ADD_GRID_STATUS_PROPS, APPLY_ALL_CHANGES
} from "../actions/actionTypes";

export default function (state = {}, action) {
  let aux = {}
  switch (action.type) {
    case CHANGE_GRID_STATUS:
      let grid = {}
      grid[action.gridName] = {
        gridDimension: action.gridDimension,
        gridSelectedRows: action.gridSelectedRows,
        gridWasClicked: action.gridWasClicked,
        gridLevel: action.gridLevel
      }
      return Object.assign({}, state, grid)
    case CHANGE_GRID_STATUS_DEV:
      aux = {}
      if (Array.isArray(action.payload)) {
        action.payload.map(r => aux[r.gridName] = { ...r })
      } else {
        aux[action.payload.gridName] = { ...action.payload }
      }
      return Object.assign({}, state, { ...aux })
    case ADD_GRID_STATUS_PROPS:
      if (!action.payload.gridName || !state[action.payload.gridName]) { console.log('Error'); return state }
      aux = _.cloneDeep(state[action.payload.gridName])
      Object.keys(action.payload).forEach(r => aux[r] = action.payload[r])
      return Object.assign({}, state, { [action.payload.gridName]: aux })
    case APPLY_ALL_CHANGES:
      aux = _.cloneDeep(state)
      Object.keys(aux).forEach(r => {
        aux[r]['applyingFiltersAndConfs'] = true
        //se están aplicando los filtros y configuraciones seleccionadas (false si ya se aplicaron, se llama con ADD_GRID_STATUS_PROPS)
      });
      return aux
    case CLEAN_GRID_CLICKS:
      let st = { ...state }
      for (let key in st) {
        st[key].gridWasClicked = false
      }
      return st
    case CLEAN_FILTER_IN_GRID:
      let cleanedGrid = {}
      cleanedGrid[action.gridName] = {
        gridDimension: action.gridDimension,
        gridSelectedRows: action.gridSelectedRows,
        gridWasClicked: action.gridWasClicked,
        gridLevel: action.gridLevel,
        gridWasRefreshed: action.gridWasRefreshed,
      }
      return Object.assign({}, state, cleanedGrid)
    case REMOVE_GRID:
      let newState = {}
      Object.keys(state).forEach(key => {
        if (key.toString() !== action.gridName.toString())
          newState[key] = state[key]
      })
      return newState
    default:
      return state
  }
}