import React from 'react'
import ReactLoading from 'react-loading'

const Loader = () => {
    return (
      <div className="container centered">
        <ReactLoading type="spinningBubbles" color="#444" className='spinCenter' />
      </div>
    )
}

export default Loader