import React from 'react'
import Loadable from 'react-loadable'

import LoginHigherOrder from '../common/loginComponent'
import LoadingComponent from '../common/loadingComponent'


const MonthlyColumnsDashboard = Loadable({
  loader: () => import(/*webpackChunkName: '_ch_catalogAnomalies'*/'./dashboard'),
  loading: () => <LoadingComponent />
});

const Dashboard = (props) => {
  return  <MonthlyColumnsDashboard secondTable={props.secondTable}/>  
}

export default LoginHigherOrder(Dashboard)