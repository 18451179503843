import * as types from '../actions/actionTypes'
import { reduceFavValues } from '../lib/favUtils'

export default function (state = {}, action) {
  switch (action.type) {
    //OTHER
    case types.SET_FORM_INITIAL_VALUES:
      return Object.assign({}, state, { initialValues: action.initialValues })
    case types.APPLY_FAVOURITES_GROUP:
      let newFavValues = {}
      if (state.favouritesList.find(r => r.value === action.payload))
        newFavValues = reduceFavValues(state.favouritesList.find(r => r.value === action.payload)['field_values'])
      return Object.assign({}, state, { favValues: newFavValues, applyingFavValues: true })
    case types.APPLIED_FAVOURITES_GROUP:
      return Object.assign({}, state, { applyingFavValues: false })
    /**
     * PENDING
     */
    case types.GET_FAVOURITES_PENDING:
    case types.SAVE_FAVOURITES_PENDING:
    case types.UPDATE_FAVOURITES_GROUP_PENDING:
    case types.DELETE_FAVOURITES_GROUP_PENDING:
      return Object.assign({}, state, { loadingFavValues: true })
    /**
     * FULFILLED
     */
    case types.GET_FAVOURITES_FULFILLED:
    case types.SAVE_FAVOURITES_FULFILLED:
    case types.UPDATE_FAVOURITES_GROUP_FULFILLED:
    case types.DELETE_FAVOURITES_GROUP_FULFILLED:
      let favValues = {}, defaultGroupObject = {}, defaultGroup = null
      if (action.payload.data.results) {
        defaultGroupObject = action.payload.data.results.find(r => r.is_default) || {} //buscar defaultGroup
        defaultGroup = defaultGroupObject.value || null //definir numero de defaultGroup
        favValues = (!state.favValues && defaultGroup) ? reduceFavValues(defaultGroupObject['field_values']) : {} //cargar nuevos favValues si es necesario
      }
      return Object.assign({}, state, { favouritesList: [...action.payload.data.results], favValues, defaultGroup, loadingFavValues: false })
    /**
     * REJECTED
     */
    case types.GET_FAVOURITES_REJECTED:
    case types.SAVE_FAVOURITES_REJECTED:
    case types.UPDATE_FAVOURITES_GROUP_REJECTED:
    case types.DELETE_FAVOURITES_GROUP_REJECTED:
      return Object.assign({}, state, { loadingFavValues: false })
    default:
      return state
  }
}
