import React from 'react'
import Loadable from 'react-loadable'

import LoginHigherOrder from '../common/loginComponent'
import LoadingComponent from '../common/loadingComponent'


const IndicatorsDashboard = Loadable({
  loader: () => import(/*webpackChunkName: 'chunk'*/'./dashboard'),
  loading: () => <LoadingComponent />
});

const Dashboard = (props) => {
  return (
    <IndicatorsDashboard
      withTotalTable={props.withTotalTable}
    />
  )
}

export default LoginHigherOrder(Dashboard)