import React, {Component} from 'react'
import { connect } from 'react-redux'
import {withRouter } from "react-router-dom";

import { authLogin } from '../../actions/authActions';

const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL
const LOGIN_TOKEN = process.env.REACT_APP_LOGIN_TOKEN_OVERRIDE

export default function (ComposedComponent){
  class Login extends Component {
  
    componentDidMount(){
       try { 
        const loginToken = LOGIN_TOKEN || this.props.location.hash.split('=')[1].split('&')[0]
        const pathname = this.props.location.pathname
        this.props.authLogin(loginToken, pathname) 
                  .then(resp=>{ 
                    if (!resp.value.data.success){
                      console.error("ERROR DE AUTENTICACION",resp.value)
                    } else {
                      console.log("autenticado correctamente")
                    }
                  })
                  .catch((e)=>{
                      console.log("autenticacion",e)
                      })
      } catch(e){
        console.error("Can't find loginToken on pathname:", e)
        window.location = REDIRECT_URL 
      }
    }
    render() {  
      
        return (
                this.props.isAuthenticated ?
                  <ComposedComponent
                    {...this.state}
                    {...this.props} 

                  /> 
                :  <div></div>)  
 
    }
  }


  return withRouter( connect(mapStateToProps,mapDispatchToProps) (Login) )
}

  const mapStateToProps = (state) => {  
    return {
      isAuthenticated: state.userProfile.isAuthenticated,
      appIsLoading: state.appStatus.isLoading,
      requestRejected : state.appStatus.requestError
    }
  };
  
  const mapDispatchToProps = {
    authLogin
  }
 

