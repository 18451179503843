import React from 'react'
import Loadable from 'react-loadable'

import LoginHigherOrder from '../common/loginComponent'
import LoadingComponent from '../common/loadingComponent'


const StatusPanel = Loadable({
  loader: () => import(/*webpackChunkName: 'ch_last_update'*/'./dashboard'),
  loading: () => <LoadingComponent />
});

const Dashboard = (props) => {
  return  <StatusPanel
            spObjective={props.spObjective}
            externalConf={props.externalConf}
          />  
}

export default LoginHigherOrder(Dashboard)