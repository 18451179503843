import React from 'react'
import Loadable from 'react-loadable'

import LoginHigherOrder from '../common/loginComponent'
import LoadingComponent from '../common/loadingComponent'


const ManagementSummaryDashboard = Loadable({
  loader: () => import(/*webpackChunkName: 'mngt_summary'*/'./dashboard'),
  loading: () => <LoadingComponent />
});

const Dashboard = (props) => {
  return  <ManagementSummaryDashboard secondTable={props.secondTable}/>  
}

export default LoginHigherOrder(Dashboard)