import React from 'react'
import Loadable from 'react-loadable'

import LoginHigherOrder from '../common/loginComponent'
import LoadingComponent from '../common/loadingComponent'


const MonthlyVariationsDashboard = Loadable({
  loader: () => import(/*webpackChunkName: '_ch_stock_with_no_sales'*/'./dashboard'),
  loading: () => <LoadingComponent />
});

const Dashboard = () => {
      return  <MonthlyVariationsDashboard  />  
}

export default LoginHigherOrder(Dashboard)