export const CHANGE_SALES_TYPE = 'CHANGE_SALES_TYPE'
export const CHANGE_MONTH_HORIZON = 'CHANGE_MONTH_HORIZON'
export const CHANGE_GRID_STATUS = 'CHANGE_GRID_STATUS'
export const CHANGE_GRID_STATUS_DEV = 'CHANGE_GRID_STATUS_DEV'
export const ADD_GRID_STATUS_PROPS = 'ADD_GRID_STATUS_PROPS'
export const CHANGE_CHART_STATUS = 'CHANGE_CHART_STATUS'
export const CLEAN_GRID_CLICKS = 'CLEAN_GRID_CLICKS'
export const FETCH_AVAILABLE_REGIONS = 'FETCH_AVAILABLE_REGIONS'

export const FETCH_MANUFACTURER_DIMENSIONS = 'FETCH_MANUFACTURER_DIMENSIONS'
export const FETCH_MANUFACTURER_PARAMETERS = 'FETCH_MANUFACTURER_PARAMETERS'
export const EXECUTE_QUERY = 'EXECUTE_QUERY'
export const FILTER_QUERY = 'FILTER_QUERY'
export const EXECUTE_PROCEDURE = 'EXECUTE_PROCEDURE'
export const FETCH_DIMENSIONS = 'FETCH_DIMENSIONS'
export const FETCH_PARAMETERS = 'FETCH_PARAMETERS'

export const AUTH_LOGIN_FULFILLED = 'AUTH_LOGIN_FULFILLED'
export const AUTH_LOGIN = 'AUTH_LOGIN'

export const CHANGE_REGION = 'CHANGE_REGION'
export const CHANGE_APPLY_BUTTON = 'CHANGE_APPLY_BUTTON'
export const CHANGE_MONTH_RANGE = 'CHANGE_MONTH_RANGE'
export const CHANGE_MONTH_HORIZON_OPTIONS = 'CHANGE_MONTH_HORIZON_OPTIONS'
export const CHANGE_MONTH_HORIZON_SELECTION = 'CHANGE_MONTH_HORIZON_SELECTION'
export const CHANGE_MONTH_HORIZON_DOH_OPTIONS = 'CHANGE_MONTH_HORIZON_DOH_OPTIONS'
export const CHANGE_MONTH_HORIZON_DOH_SELECTION = 'CHANGE_MONTH_HORIZON_DOH_SELECTION'
export const CHANGE_STOCK_TYPE = 'CHANGE_STOCK_TYPE'
export const CHANGE_STOCK_WINDOW = 'CHANGE_STOCK_WINDOW'
export const CHANGE_DATE_RANGE = 'CHANGE_DATE_RANGE'
export const CHANGE_INVENTORY_HIERARCHY_TYPE = 'CHANGE_INVENTORY_HIERARCHY_TYPE'
export const CHANGE_CATALOG_TYPE = 'CHANGE_CATALOG_TYPE'
export const CHANGE_VARIATION_SELECTION = 'CHANGE_VARIATION_SELECTION'
export const CHANGE_SHOW_DATA = 'CHANGE_SHOW_DATA'
export const CHANGE_METRIC_SCALE_FORMAT = 'CHANGE_METRIC_SCALE_FORMAT'
export const APPLY_ALL_CHANGES = 'APPLY_ALL_CHANGES'
export const APPLY_ALL_CHANGES_IS_OK = 'APPLY_ALL_CHANGES_IS_OK'
export const CHANGE_FILTER_FORM_STATUS = 'CHANGE_FILTER_FORM_STATUS'
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS'
export const PUSH_DYNAMIC_FILTERS = 'PUSH_DYNAMIC_FILTERS'
export const ERROR = 'ERROR'

export const REMOVE_GRID = 'REMOVE_GRID'
export const CLEAN_FILTER_IN_GRID = 'CLEAN_FILTER_IN_GRID'

//FAVOURITES
export const GET_APPLIED_CHANGES = 'GET_APPLIED_CHANGES'
export const GET_FAVOURITES_LIST = 'GET_FAVOURITES_LIST'

export const GET_FAVOURITES = 'GET_FAVOURITES'
export const SAVE_FAVOURITES = 'SAVE_FAVOURITES'
export const UPDATE_FAVOURITES_GROUP = 'UPDATE_FAVOURITES_GROUP'
export const DELETE_FAVOURITES_GROUP = 'DELETE_FAVOURITES_GROUP'

export const GET_FAVOURITES_PENDING = 'GET_FAVOURITES_PENDING'
export const SAVE_FAVOURITES_PENDING = 'SAVE_FAVOURITES_PENDING'
export const UPDATE_FAVOURITES_GROUP_PENDING = 'UPDATE_FAVOURITES_GROUP_PENDING'
export const DELETE_FAVOURITES_GROUP_PENDING = 'DELETE_FAVOURITES_GROUP_PENDING'

export const GET_FAVOURITES_FULFILLED = 'GET_FAVOURITES_FULFILLED'
export const SAVE_FAVOURITES_FULFILLED = 'SAVE_FAVOURITES_FULFILLED'
export const UPDATE_FAVOURITES_GROUP_FULFILLED = 'UPDATE_FAVOURITES_GROUP_FULFILLED'
export const DELETE_FAVOURITES_GROUP_FULFILLED = 'DELETE_FAVOURITES_GROUP_FULFILLED'

export const GET_FAVOURITES_REJECTED = 'GET_FAVOURITES_REJECTED'
export const SAVE_FAVOURITES_REJECTED = 'SAVE_FAVOURITES_REJECTED'
export const UPDATE_FAVOURITES_GROUP_REJECTED = 'UPDATE_FAVOURITES_GROUP_REJECTED'
export const DELETE_FAVOURITES_GROUP_REJECTED = 'DELETE_FAVOURITES_GROUP_REJECTED'

export const APPLY_FAVOURITES_GROUP = 'APPLY_FAVOURITES_GROUP'
export const APPLIED_FAVOURITES_GROUP = 'APPLIED_FAVOURITES_GROUP'

export const SET_FORM_INITIAL_VALUES = 'SET_FORM_INITIAL_VALUES'
export const CHANGE_CONFIG_PARAMS ='CHANGE_CONFIG_PARAMS'

export const PRINTING_GRIDS = 'PRINTING_GRIDS'
export const SET_GRIDS_TO_PRINT = 'SET_GRIDS_TO_PRINT'