//FAVOURITES
/**
 * 
 * @param {object} data 
 * @param {any} favValue 
 * @param {any} defaultValue 
 * @param {number} defaultIndex 
 */
export function setFavouriteToInitialValue(data, favValue, defaultValue = null, defaultIndex = -1) {
  if (typeof favValue !== 'undefined' && favValue !== null) {
    if (typeof favValue === 'number' || typeof favValue === 'string') {
      return data.find(r => String(r.value) === String(favValue))
    } else if (typeof favValue === 'object') {
      return data.filter(r => {
        return favValue.indexOf(typeof r.value === 'number' ? '' + r.value : r.value) >= 0
      })
    }
  } else if (favValue === null && defaultValue === null) {
    return data.find(r => r.value === favValue)
  } else {
    //si data[defaultIndex] existe, usar, sino usar default value (puede ser [] para seleccion multiple, eg: catalogacion)
    return (defaultIndex > -1 && data[defaultIndex]) ? data[defaultIndex] : defaultValue
  }
}

/**
 * Reduce fav values from configuration to props
 * @param {object} data 
 */
export function reduceFavValues(data) {
  const values = JSON.parse(data)
  return values.reduce((r, i) => {
    if (i['type'] === 'string') r[i['name']] = i['value']
    else if (i['type'] === 'boolean') r[i['name']] = i['value']
    else if (i['type'] === 'int') r[i['name']] = parseFloat(i['value'])
    else if (i['type'] === 'array') r[i['name']] = i['value'].split(',')
    else if (i['type'] === 'table_filters') r[i['name']] = i['value'].split(';')
    else if (i['type'] === 'variable_filters') r[i['name']] = i['value'].split(';')
    return r
  }, {})
}
/**
 * Get table data (table names and selected rows)
 * @param {Array} tables 
 */
export function getTableFilters(tables) {
  let tableFilters = {}
  tables.forEach((e, i) => {
    if (e.length > 0) {
      let [tableName, values] = e.split(':')
      tableFilters[tableName] = values ? values.split(',').map(r => r.slice(1, r.length - 1)) : []
    }
  })
  return tableFilters
}