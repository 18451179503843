import { CHANGE_CHART_STATUS } from "../actions/actionTypes";


export default function(state = [], action) { 
  switch (action.type) {
    case CHANGE_CHART_STATUS:
      return Object.assign({}, state, { chartDimensionSelection: action.dimension })
    default:
      return state;
  }
}
