export default function (state = { isLoading: 0 }, action) {
  if (action.type.substr(action.type.length - 7) === 'PENDING')
    return { isLoading: state.isLoading + 1 }
  else if (action.type.substr(action.type.length - 9) === 'FULFILLED')
    return { isLoading: state.isLoading - 1 }
  else if (action.type === 'EXECUTE_QUERY_REJECTED') {
    return { requestError: true }
  }
  else if (action.type === 'APP_ERROR') {
    return { hasErrors: true }
  }
  else
    return state
}