import { AUTH_LOGIN_FULFILLED } from "../actions/actionTypes";
import initialState from './initialState';

export default function(state = initialState.userProfile, action) {
  switch (action.type) {
    case AUTH_LOGIN_FULFILLED:  
      return {user:action.payload.data.user,
              isAuthenticated:action.payload.data.success,
              token:action.payload.data.token};

    default:
      return state;
  }
}
