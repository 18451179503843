import React from 'react'
import Loadable from 'react-loadable'

import LoginHigherOrder from '../common/loginComponent'
import LoadingComponent from '../common/loadingComponent'


const DremioOOSPanel = Loadable({
  loader: () => import(/*webpackChunkName: '_ch_dre_oos*/'./dashboard'),
  loading: () => <LoadingComponent />
});

const Dashboard = () => {
  return  <DremioOOSPanel />  
}

export default LoginHigherOrder(Dashboard)