import 'bootstrap/dist/css/bootstrap.css';  
import 'bootstrap/dist/css/bootstrap-theme.css'; 
import "ag-grid/dist/styles/ag-grid.css";
import "ag-grid/dist/styles/ag-theme-balham.css";
import './App.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store'
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
//import { unregister } from './registerServiceWorker'

ReactDOM.render(<Provider store={store}>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </Provider>,
                document.getElementById('root'));
registerServiceWorker();
//unregister()