import * as types from './actionTypes';

/**
 * Changes any param from config and filters
 * @param {Object} payload Object like { paramName1: paramValue1, ..., paramNameN: paramValueN }
 */
export function changeConfigParams(payload) {
  return {
    type: types.CHANGE_CONFIG_PARAMS,
    payload
  }
}

export function changeDateRange(rangeOne, rangeTwo) {
  return {
    type: types.CHANGE_DATE_RANGE,
    rangeOne, rangeTwo
  }
}

export function changeApplyBtn(val, months) {
  return {
    type: types.CHANGE_APPLY_BUTTON,
    applyWasClicked: val,
    monthRangeSelected: months
  }
}

export function changeMonthHorizonSelection(newMonthHorizon) {
  return {
    type: types.CHANGE_MONTH_HORIZON_SELECTION,
    monthHorizonSelection: newMonthHorizon
  }
}

export function changeMonthHorizonDohSelection(newMonthHorizon) {
  return {
    type: types.CHANGE_MONTH_HORIZON_DOH_SELECTION,
    monthHorizonDohSelection: newMonthHorizon
  }
}


export function changeSalesType(newSalesType) {
  return {
    type: types.CHANGE_SALES_TYPE,
    salesType: newSalesType
  }
}

export function changeCatalogType(newCatalogType) {
  return {
    type: types.CHANGE_CATALOG_TYPE,
    catalogType: newCatalogType
  }
}
export function changeVariationSelection(newVariationSelection) {
  return {
    type: types.CHANGE_VARIATION_SELECTION,
    variationSelection: newVariationSelection
  }
}

export function changeShowChartsAndGrids(newShowChartsAndGrids) {
  return {
    type: types.CHANGE_SHOW_DATA,
    showChartsAndGrids: newShowChartsAndGrids
  }
}

export function changeInventoryHierarchyType(newInventoryHierarchyType) {
  return {
    type: types.CHANGE_INVENTORY_HIERARCHY_TYPE,
    inventoryHierarchyType: newInventoryHierarchyType
  }
}

export function changeMetricScaleFormat(payload) {
  return {
    type: types.CHANGE_METRIC_SCALE_FORMAT,
    payload
  }
}

export function displayErrorMessage(error) {
  return {
    type: types.ERROR,
    message: error
  }
}

export function applyAllChanges(changes) {
  return {
    type: types.APPLY_ALL_CHANGES,
    changes,
  }
}
//TODO: ELiminar (aun se usa en status panel (hay que depender del grid))
export function applyAllChangesIsOK() {
  return {
    type: types.APPLY_ALL_CHANGES_IS_OK,
  }
}

export function pushDynamicFilters(filters, objective) {
  return {
    type: types.PUSH_DYNAMIC_FILTERS,
    filters,
    objective
  }
}

export function filterFormSetChangedStatus(status) {
  return {
    type: types.CHANGE_FILTER_FORM_STATUS,
    status
  }
}

export function resetAllFilters(status) {
  return {
    type: types.RESET_ALL_FILTERS,
    status
  }
}

export function setFormInitialValues(initialValues) {
  return {
    type: types.SET_FORM_INITIAL_VALUES,
    initialValues
  }
}

export function printingGrids(payload) {
  return {
    type: types.PRINTING_GRIDS,
    payload
  }
}

export function setGridsToPrint(payload) {
  return {
    type: types.SET_GRIDS_TO_PRINT,
    payload
  }
}