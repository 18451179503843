import React from 'react'
import Loadable from 'react-loadable'

import LoginHigherOrder from '../common/loginComponent'
import LoadingComponent from '../common/loadingComponent'


const PricesAnalisisDashboard = Loadable({
  loader: () => import(/*webpackChunkName: 'chunk'*/'./dashboard'),
  loading: () => <LoadingComponent />
});

const Dashboard = () => {
  return <PricesAnalisisDashboard />
}

export default LoginHigherOrder(Dashboard)