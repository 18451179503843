import {
  ERROR,
  CHANGE_STOCK_TYPE,
  CHANGE_APPLY_BUTTON,
  CHANGE_DATE_RANGE,
  CHANGE_SALES_TYPE,
  CHANGE_MONTH_HORIZON_SELECTION,
  CHANGE_MONTH_HORIZON_OPTIONS,
  CHANGE_MONTH_HORIZON_DOH_SELECTION,
  CHANGE_MONTH_HORIZON_DOH_OPTIONS,
  CHANGE_INVENTORY_HIERARCHY_TYPE,
  CHANGE_REGION,
  CHANGE_MONTH_RANGE,
  CHANGE_STOCK_WINDOW,
  CHANGE_CATALOG_TYPE,
  CHANGE_VARIATION_SELECTION,
  CHANGE_SHOW_DATA,
  APPLY_ALL_CHANGES,
  APPLY_ALL_CHANGES_IS_OK,
  PUSH_DYNAMIC_FILTERS,
  CHANGE_FILTER_FORM_STATUS,
  RESET_ALL_FILTERS,
  SET_FORM_INITIAL_VALUES,
  CHANGE_METRIC_SCALE_FORMAT,
  CHANGE_CONFIG_PARAMS,
  PRINTING_GRIDS,
  SET_GRIDS_TO_PRINT,
} from "../actions/actionTypes"

export default function (state = [], action) {
  switch (action.type) {
    case CHANGE_CONFIG_PARAMS:
      return Object.assign({}, state, { ...action.payload })
    case CHANGE_INVENTORY_HIERARCHY_TYPE:
      return Object.assign({}, state, { inventoryHierarchyType: action.inventoryHierarchyType })
    case CHANGE_APPLY_BUTTON:
      return Object.assign({}, state, { applyWasClicked: action.applyWasClicked })
    case CHANGE_SALES_TYPE:
      return Object.assign({}, state, { salesType: action.salesType })
    case CHANGE_STOCK_TYPE:
      return Object.assign({}, state, { stockType: action.stockType })
    case CHANGE_STOCK_WINDOW:
      return Object.assign({}, state, { stockWindow: action.stockWindow })
    case CHANGE_MONTH_RANGE:
      return Object.assign({}, state, { monthRange: action.monthRange })
    case CHANGE_DATE_RANGE:
      return Object.assign({}, state, { rangeOne: action.rangeOne, rangeTwo: action.rangeTwo })
    case CHANGE_MONTH_HORIZON_OPTIONS:
      return Object.assign({}, state, { monthHorizonOptions: action.monthHorizonOptions })
    case CHANGE_MONTH_HORIZON_SELECTION:
      return Object.assign({}, state, { monthHorizonSelection: action.monthHorizonSelection })
    case CHANGE_MONTH_HORIZON_DOH_OPTIONS:
      return Object.assign({}, state, { monthHorizonOptions: action.monthHorizonDohOptions })
    case CHANGE_MONTH_HORIZON_DOH_SELECTION:
      return Object.assign({}, state, { monthHorizonDohSelection : action.monthHorizonDohSelection  })
    case CHANGE_REGION:
      return Object.assign({}, state, { region: action.region })
    case CHANGE_CATALOG_TYPE:
      return Object.assign({}, state, { catalogType: action.catalogType })
    case CHANGE_VARIATION_SELECTION:
      return Object.assign({}, state, { variationSelection: action.variationSelection })
    case CHANGE_SHOW_DATA:
      return Object.assign({}, state, { showChartsAndGrids: action.showChartsAndGrids })
    case CHANGE_METRIC_SCALE_FORMAT:
      return Object.assign({}, state, { metricScaleFormat: action.payload })
    case APPLY_ALL_CHANGES:
      return Object.assign({}, state, { ...action.changes, applyingFiltersAndConfs: true })
    case APPLY_ALL_CHANGES_IS_OK:
      return Object.assign({}, state, { applyingFiltersAndConfs: false })
    case PUSH_DYNAMIC_FILTERS:
      let pushedFilters = {}
      pushedFilters[action.objective] = action.filters;
      return Object.assign({}, state, pushedFilters)
    case CHANGE_FILTER_FORM_STATUS:
      return Object.assign({}, state, { formChanged: action.status })
    case RESET_ALL_FILTERS:
      return Object.assign({}, state, { cleanAllFilters: action.status })
    case SET_FORM_INITIAL_VALUES:
      return Object.assign({}, state, { initialValues: action.initialValues })
    case ERROR:
      return Object.assign({}, state, { error: action.message })
    case PRINTING_GRIDS:
      return Object.assign({}, state, { printingGrids: action.payload })
    case SET_GRIDS_TO_PRINT:
      return Object.assign({}, state, { gridsToPrint: {...state.gridsToPrint, ...action.payload} })
    default:
      return state;
  }
}
