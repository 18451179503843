import axios from 'axios';
import store from './store';

const isfabricBackend = !!store.getState().isfabricBackend
const BACKEND_URL = isfabricBackend ? process.env.REACT_APP_BACKEND_URL_FABRIC : process.env.REACT_APP_BACKEND_URL
const BACKEND_URL_WO = process.env.REACT_APP_BACKEND_WO

export function get(url) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`;
  return axios.get(BACKEND_URL + url);
}

export function post(url, body, cancelFunction = undefined) {
  let axiosSource = axios.CancelToken.source();
  if (typeof cancelFunction === "function") {
    cancelFunction(axiosSource)
  }
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token}`;
  return (
    axios.post(BACKEND_URL + url, body, { cancelToken: axiosSource.token })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        }
      }));
}

//FAVOURITES
export function getAllFavourites(url) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token
    }`
  // return axios.get(BACKEND_URL + url)
  return axios.get(BACKEND_URL_WO + url)
}

export function saveFavourites(url, body) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token
    }`
  return axios.post(BACKEND_URL_WO + url, body)
}

export function updateFavouritesGroup(url, body) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token
    }`
  return axios.put(BACKEND_URL_WO + url, body)
}
export function deleteFavouritesGroup(url, body) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.getState().userProfile.token
    }`
  return axios.delete(BACKEND_URL_WO + url, body)
}