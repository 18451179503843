import React from 'react'
import Loadable from 'react-loadable'

import LoginHigherOrder from '../common/loginComponent'
import LoadingComponent from '../common/loadingComponent'


const DailyVariationsDashboard = Loadable({
  loader: () => import(/*webpackChunkName: 'chunk'*/'./dashboard'),
  loading: () => <LoadingComponent />
});

const Dashboard = () => {
      return  <DailyVariationsDashboard  />  
}

export default LoginHigherOrder(Dashboard)