import React from 'react'
import Loadable from 'react-loadable'

import LoginHigherOrder from '../common/loginComponent'
import LoadingComponent from '../common/loadingComponent'


const TMPerformedAllActionsList = Loadable({
  loader: () => import(/*webpackChunkName: 'ch_tm_perf_actions_open_close'*/'./dashboard'),
  loading: () => <LoadingComponent />
});

const Dashboard = (props) => {
  return <TMPerformedAllActionsList />
}

export default LoginHigherOrder(Dashboard)