import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import loginReducer from './authReducer'
import statusReducer from './appStatusReducer' 
import gridReducer from './gridReducer'
import dashboardReducer from './dashboardReducer'
import chartReducer from './chartReducer'
import favReducer from './favouritesReducer'
import fabricReducer from './fabricReducer'


const rootReducer = combineReducers({
  isfabricBackend: fabricReducer,
  userProfile: loginReducer,
  appStatus: statusReducer, 
  grids: gridReducer,
  form: formReducer,
  dashboardStatus : dashboardReducer,
  chartStatus: chartReducer,
  favourites: favReducer,
})

export default rootReducer